// start_ai_generated
import CollectionBanner from './CollectionBanner'
import CollectionRelated from './CollectionRelated'
import CollectionDescription from './CollectionDescription'
import CollectionProducts from './CollectionProducts'
import CollectionBreadCrumbs from './CollectionBreadCrumbs'
import CollectionBottomBanner from './CollectionBottomBanner'
import s from './index.module.css'
import cn from 'classnames'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { getCollection } from 'lib/shopify/api/collection'

const V3 = ({ metafields, slug, categories, shop, collections }) => {
  const [handle, setHandle] = useState(slug)
  const { locale } = useRouter()
  const [sortKey, setSortKey] = useState('MANUAL')
  const [sortKeyTheme, setSortKeyTheme] = useState('manual')
  const [sortKeyLabel, setSortKeyLabel] = useState('')
  const [sortKeyValue, setSortKeyValue] = useState('')
  const [reverse, setReverse] = useState(false)
  const [collection, setCollection] = useState(collections || {})
  const [loading, setLoading] = useState(false)
  const [banner, setBanner] = useState(collection?.metafields?.banner || {})

  const products = useMemo(() => {
    return collection?.products || []
  }, [collection?.products])

  useEffect(() => {
    if (collection?.metafields?.banner) {
      setBanner(collection?.metafields?.banner)
    }
  }, [collection?.metafields?.banner])

  useEffect(() => {
    const handler = () => {
      const { pathname } = window?.location
      if (pathname.indexOf('/collections/') > -1) {
        const _handle = pathname.split('/collections/')[1]
        setHandle(_handle)
      }
    }
    window.addEventListener('popstate', handler)
    return () => {
      window.removeEventListener('popstate', handler)
    }
  }, [])

  useEffect(() => {
    const query = location.search
    const params = new URLSearchParams(query)
    if (params.get('sort_by')) {
      setSortKeyTheme(params.get('sort_by'))
    }
  }, [])

  useEffect(() => {
    setSortKeyValue(metafields.collectionV3?.sort[0]?.value || 'manual')
    setSortKeyLabel(metafields.collectionV3?.sort[0]?.label)
    if (sortKeyTheme) {
      metafields.collectionV3?.sort?.forEach((item) => {
        if (item.value === sortKeyTheme) {
          if (item.value === 'manual') {
            setSortKey('MANUAL')
            setReverse(false)
          }
          if (item.value === 'created-descending') {
            setSortKey('CREATED')
            setReverse(true)
          }
          if (item.value === 'price-ascending') {
            setSortKey('PRICE')
            setReverse(false)
          }
          if (item.value === 'price-descending') {
            setSortKey('PRICE')
            setReverse(true)
          }
          setSortKeyLabel(item.label)
          setSortKeyValue(item.value)
        }
      })
    }
  }, [metafields.collectionV3?.sort, sortKeyTheme])

  const resetBanner = useCallback(() => {
    setBanner(collection?.metafields?.banner || {})
  }, [collection?.metafields?.banner])

  const getAllCollection = useCallback(async () => {
    if (!handle) return
    let _collection, after
    let products = []
    let hasNextPage = true
    let req = 0
    // setLoading(true)
    while (hasNextPage && req++ < 10) {
      _collection = await getCollection({
        handle,
        locale,
        queryArgument: {
          first: 250,
          ...(after && { after }),
          // ...(sortKey && { sortKey }),
          // ...(reverse && { reverse }),
        },
      })
      if (!_collection) break
      products.push(..._collection.products)
      hasNextPage = _collection.pageInfo?.hasNextPage
      after = _collection.pageInfo?.endCursor
    }
    if (_collection) {
      _collection.products = products
      setCollection(_collection)
    } else {
      setLoading(false)
      setCollection({})
    }
  }, [handle, locale])

  useEffect(() => {
    getAllCollection()
  }, [getAllCollection])

  const { related_collections } = useMemo(() => {
    return collection?.metafields || {}
  }, [collection?.metafields])

  const excludes = useMemo(() => {
    let _excludes
    metafields.collectionV3?.filters?.forEach((item) => {
      item.excludes?.forEach((exclude) => {
        if (exclude.handle === handle) {
          _excludes = exclude.filter
        }
      })
    })
    return _excludes
  }, [metafields.collectionV3?.filters, handle])

  const filters = useMemo(() => {
    let _filters
    metafields.collectionV3?.filters?.forEach((item) => {
      item.handle?.forEach((hand) => {
        if (hand === handle) {
          _filters = item.filter
        }
      })
    })
    _filters = _filters?.filter((item) => {
      return !excludes?.includes(item.id)
    })
    return _filters
  }, [metafields.collectionV3?.filters, handle, excludes])

  return (
    <div className={cn({ [s['collection-no-filters']]: !filters })}>
      <CollectionBanner
        banner={banner}
        collection={collection}
        metafields={metafields}
      />
      <CollectionProducts
        metafields={metafields}
        collection={collection}
        products={products}
        filters={filters}
        handle={handle}
        setHandle={setHandle}
        sortKey={sortKey}
        setSortKey={setSortKey}
        sortKeyLabel={sortKeyLabel}
        setSortKeyLabel={setSortKeyLabel}
        sortKeyValue={sortKeyValue}
        setSortKeyValue={setSortKeyValue}
        setReverse={setReverse}
        loading={loading}
        setLoading={setLoading}
        setBanner={setBanner}
        resetBanner={resetBanner}
      />
      {related_collections && (
        <CollectionRelated
          collections={related_collections}
          categories={categories}
        />
      )}
      <CollectionDescription collection={collection} />
      <CollectionBottomBanner metafields={metafields} />
      {metafields.belongsToCollection && (
        <CollectionBreadCrumbs
          shopCommon={metafields.shopCommon}
          secondHandle={metafields.belongsToCollectionHandle}
          secondTitle={metafields.breadcrumbsJsonLdName}
          thirdTitle={collection.name}
        />
      )}
    </div>
  )
}

export default V3
// end_ai_generated
