import metafieldsItems from '../fragments/metafields'

export const getCollectionQuery = /* GraphQL */ `
  query getCollectionByHandle(
    $handle: String!
    $first: Int
    $last: Int
    $sortKey: ProductCollectionSortKeys
    $filters: [ProductFilter!]
    $reverse: Boolean
    $after: String
    $before: String
  ) {
    collectionByHandle(handle: $handle) {
      id
      handle
      title
      description
      seo {
        title
        description
      }
      image {
        id
        width
        height
        altText
        url
      }
      products (
        first: $first, 
        last: $last,  
        after: $after, 
        before: $before, 
        reverse: $reverse, 
        sortKey: $sortKey, 
        filters: $filters, 
      ) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        filters {
          id
          label
          type
          values {
            count
            input
            label
            id
          }
        }
        edges {
          node {
            id
            title
            vendor
            handle
            tags
            description
            descriptionHtml
            productType
            onlineStoreUrl
            totalInventory
            availableForSale
            updatedAt
            priceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            compareAtPriceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            options {
              id
              name
              values
            }
            images(first: 100) {
              edges {
                node {
                  url
                  altText
                  width
                  height
                }
              }
            }
            MF_preRender: metafield(namespace: "build", key: "preRender") {
              ${metafieldsItems}
            }
            MF_seoSetting: metafield(namespace: "seo", key: "setting") {
              ${metafieldsItems}
            }
            MF_infos: metafield(namespace: "product", key: "infos") {
              ${metafieldsItems}
            }
            MF_judgemeBadge: metafield(namespace: "judgeme", key: "badge") {
              ${metafieldsItems}
            }
            MF_judgemeWidget: metafield(namespace: "judgeme", key: "widget") {
              ${metafieldsItems}
            }
            MF_images: metafield(namespace: "product", key: "images") {
							${metafieldsItems}
            }
            MF_tags: metafield(namespace: "listing", key: "tags") {
              ${metafieldsItems}
            }
            MF_collectionSku: metafield(namespace: "global", key: "collection_sku") {
              ${metafieldsItems}
            }
            MF_productSubscribe: metafield(namespace: "global", key: "subscribe") {
              ${metafieldsItems}
            }
            MF_seoHidden: metafield(namespace: "seo", key: "hidden") {
               ${metafieldsItems}
            }
            MF_productData: metafield(namespace: "product", key: "data") {
              ${metafieldsItems}
            }
            MF_productExtInfo: metafield(namespace: "product_ext_info", key: "bulling_point") {
              ${metafieldsItems}
            }
            variants(first: 100) {
              edges {
                node {
                  id
                  title
                  sku
                  availableForSale
                  requiresShipping
                  quantityAvailable
                  currentlyNotInStock
                  image {
                    altText
                    url
                    width
                    height
                  }
                  selectedOptions {
                    name
                    value
                  }
                  priceV2 {
                    amount
                    currencyCode
                  }
                  compareAtPriceV2 {
                    amount
                    currencyCode
                  }
                  MF_infos: metafield(namespace: "variant", key: "infos") {
                    ${metafieldsItems}
                  }
                  MF_images: metafield(namespace: "variant", key: "images") {
                    ${metafieldsItems}
                  }
                  MF_seoSetting: metafield(namespace: "seo", key: "setting") {
                    ${metafieldsItems}
                  }
                  MF_memberPrice: metafield(namespace: "member", key: "price") {
                  ${metafieldsItems}
                  }
                  MF_coupons: metafield(namespace: "__discountCodeApp", key: "data") {
							      ${metafieldsItems}
                  }
                  MF_memberPriceV2: metafield(namespace: "global", key: "eufy_member_price") {
                    ${metafieldsItems}
                  }
                }
              }
            }
          }
        }
      }
      MF_template : metafield(namespace: "template", key: "name") {
        ${metafieldsItems}
      }
      MF_data: metafield(
        namespace: "global"
        key: "data"
      ) {
          ${metafieldsItems}
      }
      MF_faq: metafield(
        namespace: "content"
        key: "faq"
      ) {
          ${metafieldsItems}
      }
      MF_name: metafield(
        namespace: "content"
        key: "name"
      ) {
          ${metafieldsItems}
      }
      MF_extraInfo: metafield(
        namespace: "content"
        key: "extraInfo"
      ) {
          ${metafieldsItems}
      }
      MF_quickLinks: metafield(
        namespace: "content"
        key: "quickLinks"
      ) {
          ${metafieldsItems}
      }
      MF_seoSetting: metafield(namespace: "seo", key: "setting") {
        ${metafieldsItems}
      }
      MF_pageType: metafield(namespace: "pageType", key: "category") {
        ${metafieldsItems}
      }
      MF_preRender: metafield(namespace: "build", key: "preRender") {
							${metafieldsItems}
      }
      MF_template: metafield(namespace: "template", key: "name") {
							${metafieldsItems}
      }
      MF_navSetting: metafield(namespace: "nav", key: "setting") {
							${metafieldsItems}
      }
      MF_data: metafield(
        namespace: "global"
        key: "data"
      ) {
          ${metafieldsItems}
      }
      MF_Firework: metafield(
        namespace: "component"
        key: "Firework"
      ) {
          ${metafieldsItems}
      }
      MF_component_order: metafield(namespace: "component", key: "_order") {
            ${metafieldsItems}
      }
      MF_banner: metafield(
        namespace: "global"
        key: "banner"
      ) {
          ${metafieldsItems}
      }
      MF_description: metafield(
        namespace: "global"
        key: "description"
      ) {
          ${metafieldsItems}
      }
      MF_related_collections: metafield(
        namespace: "global"
        key: "related_collections"
      ) {
          ${metafieldsItems}
      }
      MF_parent: metafield(namespace: "global", key: "parent") {
        ${metafieldsItems}
      }
      MF_subCollections: metafield(namespace: "global", key: "subCollections") {
        ${metafieldsItems}
      }
      MF_recommend: metafield(namespace: "global", key: "recommend") {
        ${metafieldsItems}
      }
      MF_filters: metafield(namespace: "global", key: "filters") {
        ${metafieldsItems}
      }
      MF_sellpoint: metafield(namespace: "global", key: "sellpoint") {
        ${metafieldsItems}
      }
      MF_compare: metafield(namespace: "global", key: "compare") {
        ${metafieldsItems}
      }
      MF_note: metafield(namespace: "global", key: "note") {
        ${metafieldsItems}
      }
      MF_media: metafield(namespace: "global", key: "media") {
        ${metafieldsItems}
      }
      MF_promotion: metafield(namespace: "global", key: "promotion") {
        ${metafieldsItems}
      }
      MF_brand: metafield(namespace: "global", key: "brand") {
        ${metafieldsItems}
      }
      MF_referralExclusiveCode: metafield(
        namespace: "global"
        key: "referralExclusiveCode"
      ) {
          ${metafieldsItems}
      }
    }
  }
`
