// start_ai_generated
import { useState } from 'react'
import s from './index.module.css'
import Script from 'next/script'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { motion } from 'framer-motion'
import { tsHtml2LocaleLink } from '@lib/utils/tools'
import { Text } from '@components/ui'
import { useRouter } from 'next/router'
import cn from 'classnames'

const CollectionDescription = ({ collection }) => {
  const { locale } = useRouter()
  const list = collection?.metafields?.description || []

  const [actives, setActives] = useState([])

  const toggleActives = (index, subIndex) => {
    setActives((prev) => {
      const _prev = JSON.parse(JSON.stringify(prev))
      if (!_prev[index]) {
        _prev[index] = []
      }
      _prev[index][subIndex] = !_prev[index][subIndex]
      return _prev
    })
  }

  const variants = {
    closed: {
      height: 0,
    },
    open: {
      height: 'auto',
    },
  }

  // Render the component
  return (
    <>
      {list && list.length > 0 ? (
        <section
          className={cn(
            s['collection-container'],
            s['collection-description-container']
          )}
        >
          <div className={s['collection-description-box']}>
            <ul className={s['collection-description-list']}>
              {list.map((item, index) => (
                <li className={s['collection-description-item']} key={index}>
                  {item.title && (
                    <h2 className={s['collection-description-title']}>
                      {item.title}
                    </h2>
                  )}
                  {item.subtitle && (
                    <h3 className={s['collection-description-subtitle']}>
                      {item.subtitle}
                    </h3>
                  )}
                  {item.description && (
                    <Text
                      variant="paragraph"
                      className={s['collection-description-content']}
                      html={tsHtml2LocaleLink({
                        html: item.description,
                        locale,
                      })}
                    ></Text>
                  )}
                  {item.list &&
                    item.list.map((subitem, subIndex) => (
                      <div
                        className={s['collection-description-subitem']}
                        key={subIndex}
                      >
                        {subitem.title && (
                          <h3
                            className={`${
                              s['collection-description-subtitle']
                            } ${
                              actives[index] && actives[index][subIndex]
                                ? s.active
                                : ''
                            }`}
                            onClick={() => toggleActives(index, subIndex)}
                          >
                            {subitem.title}
                            <ChevronDownIcon
                              className={s['collection-description-arrow']}
                            />
                          </h3>
                        )}
                        {subitem.description && (
                          <motion.div
                            variants={variants}
                            animate={
                              actives[index] && actives[index][subIndex]
                                ? 'open'
                                : 'closed'
                            }
                            className={`${s['collection-description-content']}`}
                          >
                            <div
                              className={
                                s['collection-description-content-inner']
                              }
                            >
                              {subitem.description.includes('<li>') ? (
                                <ul
                                  dangerouslySetInnerHTML={{
                                    __html: tsHtml2LocaleLink({
                                      html: subitem.description,
                                      locale,
                                    }),
                                  }}
                                />
                              ) : (
                                <Text
                                  variant="paragraph"
                                  html={tsHtml2LocaleLink({
                                    html: subitem.description,
                                    locale,
                                  })}
                                ></Text>
                              )}
                            </div>
                          </motion.div>
                        )}
                      </div>
                    ))}
                </li>
              ))}
            </ul>
          </div>
        </section>
      ) : (
        <div className={s['collection-description-h']}></div>
      )}

      {/* FAQ structured data */}
      <Script type="application/ld+json" id="question">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'FAQPage',
          mainEntity:
            list.length &&
            list?.flatMap(
              (item) =>
                item?.list?.map((subitem) => ({
                  '@type': 'Question',
                  name: subitem.title,
                  acceptedAnswer: {
                    '@type': 'Answer',
                    text: subitem.description,
                  },
                })) || []
            ),
        })}
      </Script>
    </>
  )
}

export default CollectionDescription
// end_ai_generated
