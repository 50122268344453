// start_ai_generated
import 'swiper/css'
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react'
import { Autoplay } from 'swiper'
import { formatCurrency } from '@lib/utils'
import s from './index.module.css'
import { Picture, Text } from '@components/ui'
import { useCommerce } from '@commerce'
import cn from 'classnames'
import Icon from '@components/icons'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useCoupon } from '@commerce/product/use-coupon'

const CollectionProduct = ({
  product,
  collectionV3,
  metafields,
  pageCount,
  page,
  index,
  handle,
  checkFilter,
  onBtnClick = () => {},
}) => {
  const { shop } = useCommerce()
  const [urlParams, setUrlParams] = useState('')
  const { locale } = useRouter()
  const ref = useRef(null)
  const moneyFormat = shop.moneyFormat
  const swiperRef = useRef(null)

  // 优化代码，减少复杂度和提高效率
  const productExtInfo = useMemo(() => {
    const variants = product.variants.filter(
      (variant) => variant?.availableForSale
    )
    const subtitle =
      variants.length > 1
        ? metafields.collectionV3?.options_choose?.replace(
            '$num',
            variants.length
          )
        : null
    const info = product?.metafields?.productExtInfo || []
    return (subtitle ? [subtitle, ...info] : info).slice(0, 2)
  }, [
    metafields.collectionV3?.options_choose,
    product.variants,
    product?.metafields?.productExtInfo,
  ])

  const variant = useMemo(() => {
    return product.variants.find((variant) => {
      if (product.metafields.collectionSku) {
        return (
          variant?.sku === product.metafields.collectionSku ||
          variant?.availableForSale
        )
      } else {
        return variant?.availableForSale
      }
    })
  }, [product.metafields.collectionSku, product.variants])

  const getQuery = useMemo(() => {
    let res = ''
    for (let key in checkFilter) {
      if (key !== 'fliter_category') {
        res += `&${key}=${encodeURIComponent(checkFilter[key])}`
      }
    }
    return res
  }, [checkFilter])

  const productUrl = useMemo(() => {
    const params = new URLSearchParams(urlParams)
    params.append('fliter_category', `${handle}${getQuery}`)
    return `${locale === 'us' ? '' : `/${locale}`}/products/${
      product.slug
    }?${params.toString()}`
  }, [handle, locale, product.slug, getQuery, urlParams])

  const { coupon } = useCoupon({ variant })

  const price = useMemo(() => {
    if (coupon?.variant_price4wscode) {
      return formatCurrency(moneyFormat, coupon?.variant_price4wscode)
    } else {
      return formatCurrency(moneyFormat, variant?.price)
    }
  }, [coupon?.variant_price4wscode, moneyFormat, variant?.price])

  // 优化后的代码
  const comparePrice = useMemo(
    () =>
      formatCurrency(moneyFormat, variant?.price) === price
        ? ''
        : formatCurrency(moneyFormat, variant?.price),
    [variant?.price, moneyFormat, price]
  )

  const offVal = useMemo(() => {
    if (coupon?.value_type) {
      return coupon?.value_type === 'percentage'
        ? `${Math.abs(coupon?.value)}% ${collectionV3?.off || 'OFF'}`
        : `${formatCurrency(moneyFormat, parseFloat(coupon?.fixed_value))} ${
            collectionV3?.off || 'OFF'
          }`
    } else {
      return null
    }
  }, [
    collectionV3?.off,
    coupon?.fixed_value,
    coupon?.value,
    coupon?.value_type,
    moneyFormat,
  ])

  useEffect(() => {
    setUrlParams(window.location.search)
  }, [])

  const isMemberPrice = useMemo(() => {
    const now = new Date().getTime()
    const memberExtra = metafields.memberSetting?.member_extra
    const memberPrice = variant.metafields.memberPrice
    const memberPriceStart = memberPrice?.start
    const memberPriceEnd = memberPrice?.end

    return (
      memberExtra &&
      memberPrice &&
      now > new Date(memberPriceStart).getTime() &&
      now < new Date(memberPriceEnd).getTime()
    )
  }, [metafields.memberSetting?.member_extra, variant.metafields.memberPrice])

  const hoverImg = useMemo(
    () => product?.metafields?.productData?.scene?.[0].img,
    [product]
  )

  if (!product.availableForSale) return null

  return (
    <div
      className={cn('collection-product', s['collection-product'], {
        hidden: index >= page * pageCount || index < (page - 1) * pageCount,
      })}
      ref={ref}
      index={index}
      title={product.name}
      sku={variant?.sku}
      price={price}
      variant={variant?.name}
      type={product.productType}
    >
      <div>
        {offVal && <span className={s['product-discount-tag']}>{offVal}</span>}
        <div className={cn(s['product-image-wrap'], 'group')}>
          <a href={productUrl}>
            <div className={s['product-feature-image']}>
              <Picture
                source={variant?.image?.url || product.images?.[0]?.url}
                alt={
                  variant?.image?.altText ||
                  product.images?.[0]?.altText ||
                  product?.name
                }
                className="aspect-1"
              />
              {hoverImg && (
                <Picture
                  source={hoverImg}
                  alt={
                    variant?.image?.altText ||
                    product.images?.[0]?.altText ||
                    product?.name
                  }
                  className="absolute left-0 top-0 aspect-1 opacity-0 transition-opacity duration-300 ease-in-out group-hover:min-l:opacity-100"
                />
              )}
            </div>
          </a>
        </div>
        <div className={s['product-info']}>
          <div className={s['product-info-col']}>
            <p className={s['product-tag']}>
              {product.metafields.tags ? (
                <>
                  {product.metafields.tags.map((tag, index) => {
                    if (tag.type.includes('CLtag')) {
                      return <span key={index}>{tag.value}</span>
                    }
                  })}
                </>
              ) : (
                <>
                  {product.tags?.map((tag, index) => {
                    if (tag.includes('CLtag')) {
                      return (
                        <span key={index}>{tag.replace('CLtag:', '')}</span>
                      )
                    }
                  })}
                </>
              )}
            </p>
            <p
              className={cn(s['product-name'], 'line-clamp-2')}
              tag="Auto-0000331"
            >
              {product.name}
            </p>
            {isMemberPrice && (
              <p
                className="mb-2 text-sm font-semibold text-[#ff5c00]"
                dangerouslySetInnerHTML={{
                  __html: metafields.memberSetting?.member_extra.replace(
                    '$discount',
                    variant.metafields.memberPrice?.percent
                  ),
                }}
              ></p>
            )}
            {productExtInfo.length ? (
              <div
                onMouseLeave={() => {
                  if (!swiperRef?.current?.swiper?.autoplay?.running) {
                    swiperRef?.current?.swiper?.autoplay?.start()
                  }
                }}
              >
                <Swiper
                  ref={swiperRef}
                  loop={true}
                  autoplay={{
                    speed: 2000,
                    delay: 5000,
                    pauseOnMouseEnter: true,
                  }}
                  modules={[Autoplay]}
                  direction="vertical"
                  className="h-[32px]"
                >
                  {productExtInfo.map((item, i) => (
                    <SwiperSlide key={i}>
                      <p className={cn(s['product-subtitle'], 'line-clamp-2')}>
                        {item}
                      </p>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            ) : null}
            {product.metafields?.productSubscribe && (
              <Text
                className="mt-[10px] bg-opacity-70 text-[14px] font-medium text-[#444444]"
                html={product.metafields?.productSubscribe?.tips}
              />
            )}
            <Text
              className="collection-product-rating"
              html={product?.metafields?.judgemeBadge}
            ></Text>
          </div>
          <div className={s['product-info-col']}>
            <div
              className={s['product-price']}
              onClick={() => {
                onBtnClick({ productUrl, offVal, price, comparePrice })
              }}
            >
              <span
                className={s['product-thumbnail__price']}
                tag={'Auto-0000331' + (index + 1)}
              >
                {product.availableForSale ? (
                  <>
                    <span className={s['product-thumbnail__price']}>
                      <Icon iconKey="cartv3"></Icon>
                      <span className={cn(s['cur-money'], s['money'])}>
                        {price}
                      </span>
                    </span>
                    <span className={s['product-thumbnail__was-price']}>
                      <span className={cn(s['was-price'], s['money'])}>
                        {comparePrice}
                      </span>
                    </span>
                  </>
                ) : (
                  <span className={s['product-thumbnail__sold-out']}>
                    {metafields.shopCommon?.soldOut || 'Sold Out'}
                  </span>
                )}
              </span>
            </div>
          </div>
        </div>
        {/* </a> */}
      </div>
    </div>
  )
}

export default CollectionProduct
// end_ai_generated
