import cn from 'classnames'
import Icon from '@components/icons'
import { Link, Picture } from '@components/ui'
import s from './index.module.css'
import CollectionProduct from './CollectionProduct'
import { useModal } from '@ebay/nice-modal-react'
import { useCallback, useEffect, useMemo, useState, useRef } from 'react'
import { useRouter } from 'next/router'
import jump from 'jump.js'
import { motion } from 'framer-motion'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import dayjs from 'dayjs'
import CollectionReferralExclusiveCode from './CollectionReferralExclusiveCode'
import useReferralExclusiveCode from '@components/product/ProductInfoV2/useReferralExclusiveCode'
import { track } from '@lib/track'
import { useUI } from '@components/ui'
import { CollectionProductModal } from '@components/common/Modal'
import { getCouponFromVariant } from '@commerce/product/use-coupon'

const CollectionProducts = ({
  metafields,
  collection,
  filters,
  handle,
  setHandle,
  sortKey,
  setSortKey,
  sortKeyLabel,
  setSortKeyLabel,
  sortKeyValue,
  setSortKeyValue,
  setReverse,
  loading,
  setLoading,
  setBanner,
  resetBanner,
}) => {
  const { gtmReady } = useUI()

  const { locale, push } = useRouter()
  const [page, setPage] = useState(1)
  const [checkFilter, setCheckFilter] = useState({})
  const { showReferralExclusiveCode } = useReferralExclusiveCode({
    metafields,
  })

  const [showSort, setShowSort] = useState(false)

  const [showFilter, setShowFilter] = useState(false)
  const [collapse, setCollapse] = useState(
    new Array(filters?.length).fill(true)
  )

  const [init, setInit] = useState(true)

  const stickyBar = useRef(null)
  const productBar = useRef(null)
  const collectionProductModal = useModal(CollectionProductModal)

  const variants = {
    closed: {
      height: 0,
    },
    open: {
      height: 'auto',
    },
  }

  const showClear = useMemo(() => {
    return !Object.keys(checkFilter).length
  }, [checkFilter])

  const { referralExclusiveCode } = useMemo(() => {
    return collection?.metafields || {}
  }, [collection?.metafields])

  const collectionV3 = useMemo(() => {
    return metafields.collectionV3
  }, [metafields.collectionV3])

  useEffect(() => {
    const query = location.search
    const params = new URLSearchParams(query)
    const _checkFilter = {}
    for (let key of params.keys()) {
      let flag = filters?.some((item) => item.id === key)
      if (flag || key === 'sort_by') {
        const v = params.get(key)
        _checkFilter[key] = v.split(',')
      }
    }
    // console.log(_checkFilter)
    setCheckFilter(_checkFilter)
    setInit(false)
  }, [filters])

  const pageCount = useMemo(() => {
    return collectionV3?.page_count || 18
  }, [collectionV3?.page_count])

  const title = useMemo(() => {
    let _title = collection?.name
    filters?.forEach((item) => {
      item.options?.forEach((option) => {
        if (
          item.type === 'category' &&
          option.link === '/collections/' + handle
        ) {
          _title = option.label
        }
      })
    })
    return _title
  }, [collection?.name, filters, handle])

  const title_val = useMemo(() => {
    let _title = collection?.name
    filters?.forEach((item) => {
      item.options?.forEach((option) => {
        if (
          item.type === 'category' &&
          option.link === '/collections/' + handle
        ) {
          _title = option.value
        }
      })
    })
    return _title
  }, [collection?.name, filters, handle])

  const availableProducts = useMemo(() => {
    if (!collection || !collection?.products) {
      pageGTMEvent({
        event: 'uaEvent',
        eventCategory: 'collection',
        eventAction: 'no_products_filter',
        eventLabel: '',
        nonInteraction: true,
      })
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'lp_button',
        event_parameters: {
          page_group: 'collection',
          position: '',
          button_name: 'no_products_filter',
        },
      })
      return []
    } else {
      if (gtmReady) {
        setTimeout(() => {
          track({
            event: 'view_item_list',
            product: collection?.products,
            handle,
            item_list_name: 'collections_',
            page_group: 'Product List Page',
            position: '',
            locale,
          })
        }, 500)
      }
    }
    return collection?.products.filter(
      (product) =>
        product.availableForSale && product.metafields.seoHidden !== '1'
    )
  }, [collection, handle, locale, gtmReady])

  const getDiscountPrice = useCallback((product) => {
    let variant =
      product.variants?.find((variant) => {
        if (product.metafields.collectionSku) {
          return (
            variant.sku === product.metafields.collectionSku ||
            variant.availableForSale
          )
        } else {
          return variant.availableForSale
        }
      }) || {}
    let coupon = getCouponFromVariant(variant)
    let price = variant?.price
    if (coupon) {
      price = coupon.variant_price4wscode
    }
    return price - 0
  }, [])

  const filterProducts = useMemo(() => {
    if (!availableProducts) return []
    let _availableProducts = JSON.parse(JSON.stringify(availableProducts))
    for (let key in checkFilter) {
      let values = checkFilter[key]
      if (key === 'sort_by') {
        _availableProducts = _availableProducts.sort((a, b) => {
          if (values[0] === 'price-ascending') {
            return getDiscountPrice(a) - getDiscountPrice(b)
          } else if (values[0] === 'price-descending') {
            return getDiscountPrice(b) - getDiscountPrice(a)
          } else if (values[0] === 'created-descending') {
            if (dayjs(a.updatedAt).isBefore(dayjs(b.updatedAt))) {
              return -1
            } else {
              return 1
            }
          } else {
            return 0
          }
        })
      } else if (key === 'Category') {
      } else if (key === 'Price') {
        _availableProducts = _availableProducts.filter((product) => {
          let variant = product.variants?.find((variant) => {
            if (product.metafields.collectionSku) {
              return (
                variant.sku === product.metafields.collectionSku ||
                variant.availableForSale
              )
            } else {
              return variant.availableForSale
            }
          })
          let coupon = getCouponFromVariant(variant)
          let price = variant?.price
          if (coupon) {
            price = coupon.variant_price4wscode
          }
          let flag = false
          values.forEach((value) => {
            let min, max
            if (value.includes('-')) {
              min = value.split('-')[0] - 0
              max = value.split('-')[1] - 0
              if (price >= min && price <= max) {
                flag = true
              }
            }
            if (value.includes('>')) {
              if (value.includes('=')) {
                min = value.split('=')[1] - 0
                if (price === min || price > min) {
                  flag = true
                }
              } else {
                min = value.split('>')[1] - 0
                if (price > min) {
                  flag = true
                }
              }
            }
            if (value.includes('<')) {
              if (value.includes('=')) {
                max = value.split('=')[1] - 0
                if (price === max || price < max) {
                  flag = true
                }
              } else {
                max = value.split('<')[1] - 0
                if (price < max) {
                  flag = true
                }
              }
            }
          })
          return flag
        })
      } else if (key === 'Sale') {
        _availableProducts = _availableProducts.filter((product) => {
          let variant = product.variants?.find((variant) => {
            if (product.metafields.collectionSku) {
              return (
                variant?.sku === product.metafields.collectionSku ||
                variant.availableForSale
              )
            } else {
              return variant.availableForSale
            }
          })
          return getCouponFromVariant(variant)
        })
      } else {
        _availableProducts = _availableProducts.filter((product) => {
          let flag = false
          values.forEach((value) => {
            if (
              product.tags.some((tag) => {
                return tag.toUpperCase().includes(value.toUpperCase())
              })
            ) {
              flag = true
            }
          })
          return flag
        })
      }
    }
    if (_availableProducts.length === 0) {
      pageGTMEvent({
        event: 'uaEvent',
        eventCategory: 'collection',
        eventAction: 'no_products_filter',
        eventLabel: '',
        nonInteraction: true,
      })
      pageGTMEvent({
        event: 'ga4Event',
        event_name: 'lp_button',
        event_parameters: {
          page_group: 'collection',
          position: '',
          button_name: 'no_products_filter',
        },
      })
    }
    return _availableProducts
  }, [availableProducts, checkFilter, getDiscountPrice])

  const totalPage = useMemo(() => {
    if (!filterProducts) return
    return Math.ceil(filterProducts.length / pageCount)
  }, [filterProducts, pageCount])

  const setToggleFilter = useCallback(
    (filter, option) => {
      const category = title_val || title
      if (category && category.toUpperCase() === 'ALL') {
        setBanner((_prev) => {
          let _banner = JSON.parse(JSON.stringify(_prev))
          _banner.title = option.title || _banner.title
          _banner.description = option.description || _banner.description
          _banner.image = option.image || _banner.image
          _banner.mbimage = option.mbimage || _banner.mbimage
          _banner.color = option.color || _banner.color
          return _banner
        })
      } else {
        resetBanner()
      }
      if (filter.source === 'category') {
        resetBanner()
        const { link } = option
        setHandle(link.replace('/collections/', ''))
        history.replaceState({}, document.title, location.pathname)
        setTimeout(() => {
          setCheckFilter({})
        }, 100)
        setSortKeyValue(collectionV3?.sort[0]?.value || 'manual')
        setSortKeyLabel(collectionV3?.sort[0]?.label)
        // location.href = link
      } else {
        setCheckFilter((prev) => {
          let _prev = JSON.parse(JSON.stringify(prev))
          if (!_prev[filter.id]) {
            _prev[filter.id] = []
          }
          if (_prev[filter.id].includes(option.value)) {
            _prev[filter.id] = _prev[filter.id].filter(
              (item) => item !== option.value
            )
          } else {
            if (filter.type === 'checkbox') {
              _prev[filter.id].push(option.value)
            } else {
              _prev[filter.id] = [option.value]
            }
          }
          _prev[filter.id] = [...new Set(_prev[filter.id])]
          if (_prev[filter.id].length === 0) {
            delete _prev[filter.id]
          }
          return _prev
        })
      }
      jumpTop()
      setShowFilter(false)
      setPage(1)
    },
    [
      collectionV3?.sort,
      resetBanner,
      setBanner,
      setHandle,
      setSortKeyLabel,
      setSortKeyValue,
      title,
      title_val,
    ]
  )

  useEffect(() => {
    if (init) return
    let pathname = `${
      locale === 'us' ? '' : `/${locale}`
    }/collections/${handle}`
    let query = []
    const search = location.search
    const params = new URLSearchParams(search)
    for (let key of params.keys()) {
      let flag = filters?.some((item) => item.id === key)
      if (!flag && key !== 'sort_by') {
        const v = params.get(key)
        query.push(`${key}=${v}`)
      }
    }
    for (let key in checkFilter) {
      query.push(`${key}=${encodeURIComponent(checkFilter[key].join(','))}`)
    }
    // console.log(checkFilter, filters)
    const category = title_val || title
    let flag = false
    if (category && category.toUpperCase() === 'ALL') {
      filters?.forEach((filter) => {
        for (let key in checkFilter) {
          if (key === filter.title) {
            filter.options.forEach((option) => {
              if (option.value === checkFilter[key][0]) {
                flag = true
                setBanner((_prev) => {
                  let _banner = JSON.parse(JSON.stringify(_prev))
                  _banner.title = option.title || _banner.title
                  _banner.description =
                    option.description || _banner.description
                  _banner.image = option.image || _banner.image
                  _banner.mbimage = option.mbimage || _banner.mbimage
                  _banner.color = option.color || _banner.color
                  return _banner
                })
              }
            })
          }
        }
      })
    }
    if (!flag) {
      resetBanner()
    }
    const url = query.length ? `${pathname}?${query.join('&')}` : pathname
    history.pushState(null, '', url)
  }, [
    checkFilter,
    filters,
    handle,
    init,
    locale,
    resetBanner,
    setBanner,
    title,
    title_val,
  ])

  const jumpTop = () => {
    jump('#collection-main-v3', {
      duration: 300,
      offset: -20,
    })
  }

  const toggleSortKey = (e, value, label) => {
    if (value === 'manual') {
      setSortKey('MANUAL')
      setReverse(false)
    }
    if (value === 'created-descending') {
      setSortKey('CREATED')
      setReverse(true)
    }
    if (value === 'price-ascending') {
      setSortKey('PRICE')
      setReverse(false)
    }
    if (value === 'price-descending') {
      setSortKey('PRICE')
      setReverse(true)
    }
    setSortKeyLabel(label)
    setSortKeyValue(value)
    setShowSort(false)
    setPage(1)
    e.stopPropagation()
    let pathname = `${
      locale === 'us' ? '' : `/${locale}`
    }/collections/${handle}`
    let query = []
    for (let key in checkFilter) {
      if (key !== 'sort_by') {
        query.push(`${key}=${encodeURIComponent(checkFilter[key].join(','))}`)
      }
    }
    query.push(`sort_by=${value}`)
    setCheckFilter((prev) => {
      let _prev = JSON.parse(JSON.stringify(prev))
      _prev['sort_by'] = [value]
      return _prev
    })
    const url = query.length ? `${pathname}?${query.join('&')}` : pathname
    history.pushState(null, '', url)
  }

  return (
    <div
      className={cn(s['collection-main-v3'], {
        [s.loading]: loading,
      })}
      id="collection-main-v3"
    >
      {showReferralExclusiveCode && referralExclusiveCode && (
        <CollectionReferralExclusiveCode metafields={metafields} />
      )}
      <div className={s['collection-main-v3-box']}>
        <div
          className={cn(s['collection-main-header'], {
            [s['collection-main-header-blank']]: !filters,
          })}
        >
          <div className={s['collection-main-header-inner']}>
            <div
              className={s['collection-filter-res']}
              onClick={() => {
                if (window.innerWidth < 1024) {
                  setShowFilter((prev) => !prev)
                }
              }}
            >
              {filters && (
                <div className={cn(s['label'], 'flex justify-between')}>
                  <div className="flex">
                    <Icon iconKey="filter" />
                    <label>{collectionV3?.filter}</label>
                    <span className={s['filter-count']}>
                      {
                        Object.keys(checkFilter).filter(
                          (item) => item !== 'sort_by'
                        )?.length
                      }
                    </span>
                  </div>
                  <button
                    className={cn(
                      'px-2 py-1 text-[16px] font-semibold leading-[1.2] text-[#005d8e] underline l:hidden',
                      {
                        hidden: showClear,
                      }
                    )}
                    onClick={() => {
                      setCheckFilter((prev) => {
                        let _prev = JSON.parse(JSON.stringify(prev))
                        for (let key in _prev) {
                          if (key !== 'sort_by') {
                            delete _prev[key]
                          }
                        }
                        return _prev
                      })
                      track({
                        event: 'click',
                        position: 'lp_button',
                        handle,
                        page_group: 'collection',
                        extra: {
                          button_name: collectionV3?.clear_filter,
                          position: 'collection_filter',
                        },
                      })
                    }}
                  >
                    {collectionV3?.clear_filter}
                  </button>
                </div>
              )}
              <div
                className={cn(s['result'], {
                  [s['!ml-0']]: !filters,
                })}
              >
                <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
                <span>\</span>
                <span className={s['result-num']} tag="Auto-00003010">
                  {filterProducts?.length}
                </span>
                {collectionV3?.found}
              </div>
            </div>
            <div
              className={s['collection-rank-wrap']}
              onClick={() => {
                setShowSort((prev) => !prev)
              }}
            >
              <div className={s['collection-rank-label']}>
                {collectionV3?.sort_by}
                <Icon
                  iconKey="sort"
                  className={cn('transition', {
                    'rotate-180': showSort,
                  })}
                />
              </div>
              <div className={s['collection-rank-select']}>
                <div className={s['cpr-select-wrap']}>
                  <p className={s['cpr-select-cur-value']} tag="Auto-00003011">
                    <span>{sortKeyLabel}</span>
                    <Icon
                      iconKey="sort"
                      className={cn({
                        'rotate-180': showSort,
                      })}
                    />
                  </p>
                  <div
                    className={cn(s['cpr-option-wrap'], {
                      '!visible !opacity-100': showSort,
                    })}
                  >
                    <ul className={s['cpr-option-list']}>
                      {collectionV3?.sort &&
                        collectionV3?.sort.map((item, index) => {
                          // if (item.value === 'created-descending') return
                          return (
                            <li
                              tag={'Auto-00003011' + (index + 1)}
                              className={cn(s['cpr-option-item'], {
                                [s.active]: item.value === sortKeyValue,
                              })}
                              key={index}
                              onClick={(e) => {
                                toggleSortKey(e, item.value, item.label)
                              }}
                            >
                              {item.label}
                            </li>
                          )
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cn(s['result'], s['mob-result'])}>
          <em>{title}</em>
          <span>\</span>
          <span className={s['result-num']}>{filterProducts?.length}</span>{' '}
          {collectionV3?.found}
        </div>
        <div className={s['collection-main-body']}>
          {filters && (
            <>
              <div
                className={cn(s['collection-filter-shade'], {
                  '!pointer-events-auto !opacity-50': showFilter,
                })}
              ></div>
              <div
                className={cn(s['collection-filter-bar'], {
                  [s['actived']]: showFilter,
                })}
              >
                <div className={s['mb-collection-filter-header']}>
                  <div className={s['mbc-header-inner']}>
                    <div className="flex items-center justify-center gap-4">
                      <p className={s['mbc-header-title']}>
                        {collectionV3?.filter}
                      </p>
                      <button
                        className={cn(
                          'px-2 py-1 text-[16px] font-semibold leading-[1.2] text-[#005d8e] underline min-l:hidden',
                          { hidden: showClear }
                        )}
                        onClick={() => {
                          setCheckFilter((prev) => {
                            let _prev = JSON.parse(JSON.stringify(prev))
                            for (let key in _prev) {
                              if (key !== 'sort_by') {
                                delete _prev[key]
                              }
                            }
                            return _prev
                          })
                          setShowFilter(false)
                          track({
                            event: 'click',
                            position: 'lp_button',
                            handle,
                            page_group: 'collection',
                            extra: {
                              button_name: collectionV3?.clear_filter,
                              position: 'collection_filter',
                            },
                          })
                        }}
                      >
                        {collectionV3?.clear_filter}
                      </button>
                    </div>
                    <a
                      className={s['mbc-filter-colse-btn']}
                      onClick={() => setShowFilter(false)}
                    >
                      <Icon iconKey="closev3" />
                    </a>
                  </div>
                </div>
                <div
                  className={cn(s['collection-filter-sticky-bar'], {
                    // [s.fixed]: isFixed,
                    // [s.absolute]: isAbsolute,
                  })}
                  ref={stickyBar}
                >
                  {filters.map((filter, index) => {
                    return (
                      <div
                        className={s['collapse-wrap']}
                        data-open={filter.open}
                        data-filter={filter.title}
                        key={index}
                        tag={'Auto-000033' + (index + 2) + 2}
                      >
                        <div
                          className={s['collapse-head']}
                          onClick={() => {
                            setCollapse((prev) => {
                              let _prev = [...prev]
                              _prev[index] = !_prev[index]
                              return _prev
                            })
                          }}
                        >
                          <h2 className={s['collapse-title']}>
                            {filter.title}
                          </h2>
                          <span className={s['collapse-icon']}>
                            <Icon
                              iconKey="arrowv3"
                              className={cn('transition', {
                                '!rotate-0': collapse[index],
                              })}
                            />
                          </span>
                        </div>
                        <motion.div
                          variants={variants}
                          animate={collapse[index] ? 'open' : 'closed'}
                          className="overflow-hidden"
                        >
                          <div className={s['collapse-body']}>
                            <ul
                              className={s['filter-list']}
                              data-input-type={filter.type}
                              data-value={filter.value.replace(',', '.')}
                              data-source-type={filter.source}
                            >
                              {filter.options.map((option, i) => {
                                if (option.excludes?.includes(handle)) return
                                const categoryActived =
                                  option.checked ||
                                  (filter.type === 'category' &&
                                    `/collections/${handle}` === option.link)
                                const actived =
                                  checkFilter[filter.id] &&
                                  checkFilter[filter.id].includes(option.value)
                                return (
                                  <li
                                    className={cn(
                                      s['filter-list-item'],
                                      s[`filter-${filter.type}-item`],
                                      {
                                        [s.actived]: categoryActived || actived,
                                      }
                                    )}
                                    key={i}
                                    onClick={() =>
                                      setToggleFilter(filter, option)
                                    }
                                  >
                                    {option.link ? (
                                      <Link
                                        href={option.link}
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <span>{option.label}</span>
                                      </Link>
                                    ) : (
                                      <span>{option.label}</span>
                                    )}
                                    {/* {filter.type === 'category' ? (
                                    <a
                                      href={
                                        option.link ||
                                        `/collections/${option.label}`
                                      }
                                    >
                                      <span>{option.label}</span>
                                    </a>
                                  ) : (
                                    option.label
                                  )} */}
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </motion.div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </>
          )}
          <div className={s['collection-product-bar']} ref={productBar}>
            {(!filterProducts || filterProducts.length === 0) && (
              <div
                className={cn(
                  s['collection-product-contain'],
                  s['collection-no-product-contain'],
                  {
                    '!block':
                      !filterProducts.length && availableProducts.length,
                  }
                )}
              >
                <div className={s['collection-has-no-product']}>
                  <Picture
                    source={collectionV3?.no_filter_product_image}
                    className={s['no-product-image']}
                    alt="No Product"
                  />
                  <p className={s['no-product-text']}>
                    {collectionV3?.no_filter_product_text}
                  </p>
                  <button
                    className={cn(
                      s['collection-button'],
                      s['collection-button-primary-accent']
                    )}
                    onClick={() => {
                      setCheckFilter((prev) => {
                        let _prev = JSON.parse(JSON.stringify(prev))
                        for (let key in _prev) {
                          if (key !== 'sort_by') {
                            delete _prev[key]
                          }
                        }
                        return _prev
                      })
                    }}
                  >
                    {collectionV3?.clear_filter}
                  </button>
                </div>
              </div>
            )}
            <div
              className={cn(s['collection-product-contain'], {
                '!ml-0 !block': !availableProducts.length,
              })}
              style={filters === '' ? { marginLeft: '0!important' } : {}}
            >
              {filterProducts &&
                filterProducts.map((product, index) => (
                  <CollectionProduct
                    product={product}
                    collectionV3={collectionV3}
                    metafields={metafields}
                    pageCount={pageCount}
                    page={page}
                    index={index}
                    key={index}
                    handle={handle}
                    checkFilter={checkFilter}
                    onBtnClick={({
                      productUrl,
                      offVal,
                      price,
                      comparePrice,
                    }) => {
                      collectionProductModal.show({
                        product,
                        metafields,
                        productUrl,
                        offVal,
                        price,
                        comparePrice,
                      })
                    }}
                  />
                ))}
              {availableProducts && !availableProducts.length && (
                <div
                  className={cn(
                    s['collection-product-contain'],
                    s['collection-no-product-contain'],
                    s['collection-no-product-all']
                  )}
                >
                  <div className={s['collection-has-no-product']}>
                    <Picture
                      source={collectionV3?.no_product_image}
                      className={s['no-product-image']}
                      alt="No Product"
                    />
                    <p className={s['no-product-text']}>
                      {collectionV3?.no_product_text}
                    </p>
                    <button
                      className={cn(
                        s['collection-button'],
                        s['collection-button-primary-accent']
                      )}
                      onClick={() => {
                        push(`/${locale}`)
                      }}
                    >
                      {collectionV3?.back_to_homepage}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className={s['collection-paginate']}>
              {totalPage > 1 &&
                new Array(totalPage).fill(0).map((_, index) => {
                  const _page = index + 1
                  return (
                    <span
                      key={index}
                      tag="Auto-000034"
                      className={cn(s['paginate-item'], {
                        [s['active']]: _page === page,
                      })}
                      onClick={() => {
                        setPage(_page)
                        jumpTop()
                      }}
                    >
                      {_page}
                    </span>
                  )
                })}
            </div>
            <div className={s['collection-end']}>
              {/* <span>{collectionV3?.all}</span> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionProducts
