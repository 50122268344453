import 'swiper/css'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide, SwiperRef } from 'swiper/react'
import { Pagination } from 'swiper'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useMemo, useRef, useState } from 'react'
import type { Product } from '@commerce/types/product'
import Mask from '@components/common/Mask'
import Icon from '@components/icons'
import { Text, Picture } from '@components/ui'
import Button from '@components/home/Button'
import ProductDescription from '@components/product/ProductDescription'
import useCommerce from '@components/product/ProductCheckoutBar/useCommerce'
import useCustomer from '@shopify/customer/use-customer'
import { getReferralSourceCustomAttr } from '@lib/utils/cookies'
import cn from 'classnames'
import ArrowsLeft from '@components/icons/common/ArrowsLeft.svg'
import ArrowsRight from '@components/icons/common/ArrowsRight.svg'
import { track } from '@lib/track'
import { useRouter } from 'next/router'

type CollectionProductModalProps = {
  product: Product
  metafields: any
  productUrl: string
  offVal?: string
  price?: string
  comparePrice?: string
}

const CollectionProductModal = NiceModal.create(
  ({
    product,
    metafields,
    productUrl,
    offVal,
    price,
    comparePrice,
  }: CollectionProductModalProps) => {
    const modal = useModal()
    const swiperRef = useRef<SwiperRef>(null)
    const [btnShow, setBtnShow] = useState(false)
    const { shopCommon, referrals } = metafields

    const variant = useMemo(() => {
      return product.variants.find((variant) => {
        if (product?.metafields?.collectionSku) {
          return (
            variant?.sku === product.metafields.collectionSku ||
            variant?.availableForSale
          )
        } else {
          return variant?.availableForSale
        }
      })
    }, [product?.metafields?.collectionSku, product.variants])

    const { data: customer } = useCustomer()

    const { buyNow } = useCommerce()

    const { locale, asPath } = useRouter()

    const login_status = customer ? 'member' : 'non-member'
    const page_group = 'collection'
    const position = `${page_group}_Card`

    const loginAttr = useMemo(() => {
      return customer ? [{ key: '_login_user', value: '1' }] : []
    }, [customer])

    const url = useMemo(() => {
      const res = productUrl.replace(
        /([?&])ref=[^&]*(&|$)/,
        (match, p1, p2) => {
          // 如果 ref 参数是第一个参数，保留问号，否则保留 & 符号
          return p1 === '?' ? (p2 ? '?' : '') : p2
        }
      )

      return `${res}&ref=${encodeURIComponent(asPath.split('?')[0])}`
    }, [productUrl, asPath])

    return (
      <div className="fixed left-0 top-0 z-50 h-full w-full leading-[1.2]">
        <Mask />
        <style jsx global>
          {`
            .swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination {
              bottom: 22px;
            }
            .swiper-pagination-bullet {
              opacity: 1;
              position: relative;
              background: #e5e5e5;
            }
            .swiper-pagination-bullet-active {
              background: #333;
            }
            @media screen and (min-width: 1024px) {
              .swiper-pagination-bullets.swiper-pagination-horizontal.swiper-pagination {
                bottom: 9px;
              }
            }

            .listing-description ul li {
              margin-bottom: 16px;
              color: #777;
              line-height: 1.4 !important;
            }

            .listing-description ul * {
              font-size: 16px !important;
              line-height: 1.4 !important;
            }

            .listing-description ul strong {
              font-weight: 600;
            }

            .listing-description ul li .ace-line {
              color: #777;
            }

            .listing-description li span,
            .listing-description li div {
              color: #777;
            }

            .cpm-tag:after {
              content: '';
              position: absolute;
              top: 50%;
              right: -1px;
              height: 70%;
              width: 2px;
              background-color: #005d8e;
              transform: translateY(-50%);
            }

            .cpm-tag:last-child:after {
              display: none;
            }
          `}
        </style>
        <div className="relative box-border flex h-full justify-center pt-[100px] min-l:py-12">
          <div className="h-full w-full rounded-[10px] bg-white min-l:w-[640px]">
            <div className="flex items-center justify-between border-b-[1px] border-b-[#e8e8e8] p-6">
              <Text
                html={shopCommon?.quickView}
                className="text-[18px] font-semibold"
              />
              <Icon
                iconKey="close"
                className="h-6 w-6 cursor-pointer"
                onClick={() => {
                  modal.remove()
                  track({
                    event: 'click',
                    position: 'lp_button',
                    handle: asPath,
                    page_group: 'collection',
                    extra: {
                      button_name: 'close',
                      position: 'collection_product_pop',
                    },
                  })
                }}
              />
            </div>
            <div className="h-[calc(100%-73px)] overflow-y-auto">
              <div
                className="relative"
                onMouseEnter={() => setBtnShow(true)}
                onMouseLeave={() => setBtnShow(false)}
              >
                <Swiper
                  ref={swiperRef}
                  pagination={{ enabled: true, clickable: true }}
                  loop={product?.images?.length > 1 ? true : false}
                  modules={[Pagination]}
                  className="h-[240px] bg-[#f7f8f9] min-md:h-[380px] min-l:h-[320px]"
                >
                  {product?.images?.map((image, i: number) => (
                    <SwiperSlide key={i}>
                      <div className="relative flex items-center justify-center">
                        {i === 0 && offVal && (
                          <span className="absolute left-[22px] top-[22px] rounded-[20px] bg-[#B3ECFB] px-[10px] py-1 text-[14px] font-semibold leading-[1.2] text-brand-color">
                            {offVal}
                          </span>
                        )}
                        {
                          <Picture
                            // @ts-ignore
                            className="w-[210px] object-cover min-md:w-[348px] min-l:w-[320px]"
                            source={image.url}
                            alt={image.alt || image.altText}
                            imgWidth={320}
                            imgHeight={320}
                            loading="eager"
                            imgClassName=""
                          />
                        }
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {product?.images && product?.images?.length > 1 && (
                  <>
                    <a
                      onClick={() => swiperRef.current?.swiper?.slidePrev()}
                      className={cn(
                        'invisible absolute left-[24px] top-[50%] z-[2] -mt-[24px] flex h-[48px] w-[48px] cursor-pointer items-center justify-center rounded-full bg-white opacity-0 transition-opacity duration-300 ease-linear',
                        {
                          '!visible opacity-100': btnShow,
                        },
                        'l:!hidden'
                      )}
                    >
                      <ArrowsLeft className="h-6 w-6" />
                    </a>
                    <a
                      onClick={() => swiperRef.current?.swiper?.slideNext()}
                      className={cn(
                        'invisible absolute right-[24px] top-[50%] z-[2] -mt-[24px] flex h-[48px] w-[48px] cursor-pointer items-center justify-center rounded-full bg-white opacity-0 transition-opacity duration-300 ease-linear',
                        {
                          '!visible opacity-100': btnShow,
                        },
                        'l:!hidden'
                      )}
                    >
                      <ArrowsRight className="h-6 w-6" />
                    </a>
                  </>
                )}
              </div>
              <div className="px-6 py-8">
                <div className="border-b-[1px] border-b-[#f3f3f3] pb-8 text-center">
                  <Text className="mb-2 text-[16px] font-semibold text-[#005d8e]">
                    {product?.metafields?.tags ? (
                      <>
                        {product?.metafields?.tags.map(
                          (
                            tag: { type: string; value: string },
                            index: number
                          ) => {
                            if (tag.type.includes('CLtag')) {
                              return (
                                <span
                                  className="cpm-tag relative inline-block px-1"
                                  key={index}
                                >
                                  {tag.value}
                                </span>
                              )
                            }
                          }
                        )}
                      </>
                    ) : (
                      <>
                        {product?.tags?.map((tag, index) => {
                          if (tag.includes('CLtag')) {
                            return (
                              <span
                                className="cpm-tag relative inline-block px-[6px]"
                                key={index}
                              >
                                {tag.replace('CLtag:', '')}
                              </span>
                            )
                          }
                        })}
                      </>
                    )}
                  </Text>
                  <Text
                    html={product.name}
                    className="mb-4 line-clamp-2 text-[20px] font-bold"
                  ></Text>
                  <Text className="mb-4 flex justify-center gap-2 text-[20px] font-semibold">
                    {price && <Text className="text-[#333]">{price}</Text>}
                    {comparePrice && (
                      <Text className="text-[#aaa] line-through">
                        {comparePrice}
                      </Text>
                    )}
                  </Text>
                  {variant?.availableForSale ? (
                    <Button
                      variant="primary"
                      className="w-full min-md:w-auto min-md:min-w-[342px]"
                      onClick={() => {
                        const referralCustomAttr = getReferralSourceCustomAttr({
                          referrals,
                        })

                        buyNow({
                          products: [product],
                          customAttributes: [
                            ...referralCustomAttr,
                            ...loginAttr,
                          ],
                          callBack: () => {
                            track({
                              event: 'buynow',
                              product: [product],
                              page_group,
                              sku: variant?.sku,
                              login_status,
                              position,
                              locale,
                            })
                          },
                        })
                      }}
                    >
                      {shopCommon.buyNow}
                    </Button>
                  ) : (
                    <Button variant="primary" disabled={true}>
                      {shopCommon.soldOut}
                    </Button>
                  )}
                </div>
                <div className="mt-8 text-[16px] font-semibold leading-[1.4]">
                  <Text
                    className="mb-4"
                    html={shopCommon.productQuickDetail}
                  ></Text>
                  <ProductDescription
                    product={product}
                    metafields={metafields}
                    variantMetafields={variant?.metafields || {}}
                    showFoldBtn={false}
                  />
                  <Button
                    variant="link"
                    className="mt-4 !text-[#005d8e]"
                    href={url}
                  >
                    {shopCommon.learn_more}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
)

NiceModal.register('collection-product-modal', CollectionProductModal)

export default CollectionProductModal
