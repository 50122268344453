import { FC, useMemo } from 'react'
import { Text, Picture, Link } from '@components/ui'
import cn from 'classnames'
import s from './index.module.css'

interface CollectionBottomBannerProps {
  metafields: any
}

type Image = {
  screen: number
  url: string
}

const CollectionBottomBanner: FC<CollectionBottomBannerProps> = ({
  metafields,
}) => {
  const { collectionV3 } = metafields
  const support = collectionV3?.support

  const source = useMemo(() => {
    return (
      support?.images
        ?.map(
          (item: Image) =>
            `${item.url} ${item.screen === 1280 ? '' : item.screen}`
        )
        .reverse()
        .join(',') || ''
    )
  }, [support?.images])

  if (!support) return null
  return (
    <div className={cn(s['collection-container'], '!pt-0')}>
      <div className="relative h-full">
        <Link
          className="absolute left-0 top-0 flex h-full w-full items-center px-4 leading-[1.4] text-[#333] min-md:px-8"
          href={support?.href}
        >
          <div className="w-1/2 min-l:w-[60%]">
            <Text
              className="mb-1 text-[16px] font-bold min-md:text-[20px] min-xl:text-[26px]"
              html={support?.title}
            ></Text>
            <Text
              className="text-[12px] font-medium min-md:text-[14px] min-xl:text-[18px]"
              html={support?.desc}
            ></Text>
          </div>
        </Link>
        <Picture
          // @ts-ignore
          source={source}
        />
      </div>
    </div>
  )
}

export default CollectionBottomBanner
