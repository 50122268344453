import Cookies from 'js-cookie'
import useSWR, { preload } from 'swr'
import { useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { Collection as CollectionType } from 'lib/shopify/types'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { getCollectionQuery } from 'lib/shopify/queries/collectionByHandle'
import { normalizeCategory } from 'lib/utils/normalize'

export const validSortKey = ['PRICE', 'RELEVANCE']

preload('/api/user', getCollection)

export async function getCollection(input: CollectionType.CollectionQuery) {
  const { handle, locale, queryArgument } = input
  const { res } = await fetchGraphqlApi<CollectionType.GetCollectionOperation>({
    locale,
    query: getCollectionQuery,
    variables: {
      handle,
      first: queryArgument?.first,
      last: queryArgument?.last,
      after: queryArgument?.after,
      before: queryArgument?.before,
      reverse: queryArgument?.reverse,
      filters: queryArgument?.filters,
      sortKey: queryArgument?.sortKey || 'COLLECTION_DEFAULT',
    },
    cache: 'no-store',
  })
  const collectionByHandle = res?.collectionByHandle

  return collectionByHandle ? normalizeCategory(collectionByHandle) : null
}

export function useFilterCollection(
  handle: string,
  queryArgument: CollectionType.CollectionQuery,
  initData?: any
) {
  const { locale = 'us' } = useRouter()

  const response = useSWR({ locale, handle, queryArgument }, getCollection, {
    keepPreviousData: true,
    fallbackData: initData,
  })

  return useMemo(
    () =>
      Object.defineProperties(response, {
        isEmpty: {
          value: !response.data,
          enumerable: true,
        },
        isLoading: {
          value: response.isLoading && !response?.data?.initData,
          enumerable: true,
        },
      }),
    [response]
  )
}
